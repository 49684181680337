import { useEffect, useState, createContext } from "react";
import Page_Start from "Pages/Start";
import Page_Map from "Pages/Map";
import Page_MapAll from "Pages/MapAll";
import Page_AR from "Pages/AR";
import GPS from "features/GPS";
import Compass from "features/Compass";
import Camera from "features/Camera";
import WakeLock from "features/WakeLock";
import Language from "features/Language";
import { modeList, gpsArea } from "datas";
import Menu from "components/Menu";
import GuideMessage from "components/GuideMessage";
import Loading from "components/Loading";

//デバイス管理クラス　gpsは第二引数を設定したらデバッグ動作
//export const gps = new GPS(gpsArea, { lat: 34.684081, lon: 135.190149 });
export const gps = new GPS(gpsArea);
export const compass = new Compass();
export const camera = new Camera();
export const wakelock = new WakeLock();
export const language = new Language();

//コンテキスト（グローバル値）
export const contextGlobal = createContext();

function App() {
  // mode:全体管理ステートメント 状態一覧はdatas.jsに記載（アニメ切替したいのでルーターは使わない）
  const [mode, setMode] = useState(modeList[0]);
  // spots:スポット情報ステートメント（jsonデータ配列）
  const [spots, setSpots] = useState(null);
  // spotID:ユーザーが選択したスポットのインデックス
  const [spotID, setSpotID] = useState(null);
  //横向き判定
  const [landscape, setLandscape] = useState(null);
  //言語判定
  const [lan, setLan] = useState(null);
  //ローディング表示フラグ
  const [loadFlg, setLoadFlg] = useState(true);
  //初期読込画像
  const [loadImgs, setLoadImgs] = useState([]);

  //コンテキスト設定（グローバル値）
  const contextVal = {
    mode: { val: mode, set: setMode },
    spots: { val: spots, set: setSpots },
    spotID: { val: spotID, set: setSpotID },
    landscape: { val: landscape, set: setLandscape },
    lan: { val: lan, set: setLan },
    loadFlg: { val: loadFlg, set: setLoadFlg },
    loadImgs: { val: loadImgs, set: setLoadImgs },
  };

  //---useEffects
  useEffect(() => {
    //スポット情報取得・ステートメントへ保存処理
    fetch("spotsData/list.json")
      .then((data) => {
        return data.json();
      })
      .then((json) => {
        setSpots(json.list);
      });
    //言語情報
    setLan(language.code);
    //スマホ横向き判定
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    loadingInit();
  }, [spots]);

  //---handles
  /**
   * リサイズ処理
   */
  function handleResize() {
    //横と縦の比率
    const windowRatio = window.innerWidth / window.innerHeight;
    if (windowRatio > 1.5) {
      setLandscape(true);
    } else {
      setLandscape(false);
    }
  }
  /**
   * 初期ロード処理
   */
  function loadingInit() {
    if (!spots) return;
    const loadingList = spots.map((spotData) => {
      return "spotsData/" + spotData.path + "/thum.jpg";
    });
    loadingList.push("images/title.png");
    loadingList.push("images/guide1_ja.png");
    loadingList.push("images/guide1_en.png");
    loadingList.push("images/guide1_cn.png");
    loadingList.push("images/guide1_tw.png");
    loadingList.push("images/guide1_ko.png");
    loadingList.push("images/guide2_ja.png");
    loadingList.push("images/guide2_en.png");
    loadingList.push("images/guide2_cn.png");
    loadingList.push("images/guide2_tw.png");
    loadingList.push("images/guide2_ko.png");
    loadingList.push("images/guide3_ja.png");
    loadingList.push("images/guide3_en.png");
    loadingList.push("images/guide3_cn.png");
    loadingList.push("images/guide3_tw.png");
    loadingList.push("images/guide3_ko.png");
    loadingList.push("images/guide4_ja.png");
    loadingList.push("images/guide4_en.png");
    loadingList.push("images/guide4_cn.png");
    loadingList.push("images/guide4_tw.png");
    loadingList.push("images/guide4_ko.png");
    loadingList.push("images/guide5_ja.png");
    loadingList.push("images/guide5_en.png");
    loadingList.push("images/guide5_cn.png");
    loadingList.push("images/guide5_tw.png");
    loadingList.push("images/guide5_ko.png");
    setLoadImgs(loadingList);
  }

  return (
    <contextGlobal.Provider value={contextVal}>
      <div className={`App lan-${lan}`}>
        <div id="debug">debug</div>
        <Loading />
        <Menu />
        <Page_Start />
        {mode != modeList[0] && (
          <>
            <GuideMessage />
            <Page_Map />
            <Page_MapAll />
          </>
        )}
        {mode == modeList[4] && <Page_AR />}
      </div>
    </contextGlobal.Provider>
  );
}

export default App;
